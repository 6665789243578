@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --font-base: "Lato", sans-serif;
  /* --font-base: "Comfortaa", sans-serif; */
  /* --font-alt: "Roboto", sans-serif; */
  --color-white: #ffffff;
  --color-gray: #808080;
  --color-lightgray: lightgray;
  --color-whitespmoke: #f5f5f5;
  --color-darkgray: #a9a9a9;
  --color-papayawhip: papayawhip;
  --color-green-shade: #16a571;
  --color-darkgreen: #003e29;
  --color-darkolivegreen: #008c5d;
  --color-darkolivegreen-shade: #006543;

  --color-black: #0d0202;
  --color-black-shade: rgb(34, 37, 41);
}
/* #003e29 */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-base);
  /* font-family: "Lato", sans-serif !important; */
  initial-scale: 1 !important;
}

.button {
  padding: 1rem;
  cursor: pointer;
  color: var(--color-white);
  font-weight: 900;
  /* background-color: var(--color-darkolivegreen-shade); */
  /* background-color: #ffbf78; */
  background-color: #f69220;
  border: none;
  border-radius: 24px;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  word-spacing: 2px;
  letter-spacing: 1px;
  border: 2px solid var(--color-white);
}

.button:hover {
  border: 2px solid #f69220;
  background-color: var(--color-white);
  /* color: var(--color-darkolivegreen-shade); */
  color: #f69220;
  transition: all 0.4s ease-in-out;
}

.link_decor {
  text-decoration: none;
  /* color: var(--color-black); */
  color: #f69220;
}

.heading_components {
  font-size: 35px;
  /* color: var(--color-darkolivegreen-shade); */
  color: #f69220;
  text-align: center;
}

p {
  line-height: 1.3;
  letter-spacing: 0.5px;
}

.Tools_main {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 3rem;
  /* gap: 1rem; */
}

.Tools_sections_main {
  display: flex;
  gap: 4rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tools_img_main {
  height: 350px;
  width: 350px;
}
.tools_img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* .tools_btns_main_home {
  display: flex;
  flex-direction: column;
  gap: 1rem;
} */

/* .tools_btn_home {
  background: var(--color-white);
  padding: 1rem;
  font-size: 16px;
  display: flex;
  gap: 0.6rem;
  width: 100%;
  align-items: center;
} */

.faq_services_inner {
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.35);
  padding: 1rem;
  /* gap: 0.4rem; */
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  width: 500px;
  cursor: pointer;
  /* transition: all 2s  ease; */
  /* transition: width 1s ease-in; */
  padding-bottom: 7px;
}

.faq-question {
  text-transform: uppercase;
  display: flex;
  gap: 10px;
  align-items: center;
  word-spacing: 1px;
  letter-spacing: 1px;
}

.question_h3 {
  color: #f69220;
  font-size: 25px;
}

.icon_main {
  /* color: var(--color-darkolivegreen); */
  color: #f69220;
  font-size: 25px;
  display: flex;
}

.faq_services_main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;
}
/* .faq-question-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
} */

.faq-answer-main {
  /* transition: all 5s ease-in-out; */
}

.faq-answer {
  line-height: 1.3;
  /* margin-top: 1rem; */
}

.faq-answer_margin_not {
  /* margin-top: 0rem !important; */
}

/*  */

.faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 500ms ease-in-out, max-height 1000ms ease-in-out;
}

.faq-answer.visible {
  opacity: 1;
  max-height: 1000px; /* Adjust to a suitable value */
}

.faq-answer.hidden {
  opacity: 0;
  max-height: 0;
  margin-top: 0px;
}

@media screen and (max-width: 1000px) {
  .Tools_sections_main {
    align-items: center;
    flex-direction: column;
    gap: 0px;
    margin-top: 0px;
  }
}
@media screen and (max-width: 600px) {
  .faq_services_inner {
    width: 100%;
  }
  .faq_services_main {
    align-items: center;
  }
  .tools_img_main {
    margin: 1rem;
    padding: 1rem;
  }
}
@media screen and (max-width: 400px) {
  .faq_services_inner {
    width: 88%;
  }
  .question_h3 {
    width: 296px;
    font-size: 14px !important;
  }
  .icon_main {
    font-size: 20px;
  }
}

.ServicesHome_main_sec {
  /* background-color: var(--color-darkolivegreen); */
}
.ServicesHome_main {
  border-radius: 2rem;
  /* background-color: #e3f9f5; */
  background-color: #fcf1e3;
  margin: 0 auto;
  margin-bottom: 3rem;
  max-width: 1140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 2.5rem;
  /* padding-bottom: 2.5rem; */
  position: relative;
}

.costeffectivesol {
  height: 150px;
  width: 150px;
  position: absolute;
  top: -59px;
  left: -51px;
}

.ServicesHome_para {
  padding: 0 0.8rem;
  max-width: 700px;
  text-align: center;
  line-height: 1.3;
  letter-spacing: 0.3px;
  margin-top: 2rem;
}

.ServicesHome_innerpara {
  line-height: 1.3;
  letter-spacing: 1px;
}

.services_main {
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.services_main_inner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  width: 450px;
  /* max-width: 550px; */
  transition: all 0.1s ease-in-out;
}

.services_main_inner:hover {
  background-color: var(--color-white);
  border-radius: 2rem;
  transition: all 0.3s ease-in-out;
}
.sevices_icon {
  height: 80px;
  width: 80px;
}

@media screen and (max-width: 1230px) {
  .ServicesHome_main {
    margin: 0 1.5rem;
    margin-bottom: 3rem;
  }
}
@media screen and (max-width: 1000px) {
  .services_main {
    flex-direction: column;
  }
  .services_main_inner {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .costeffectivesol {
    height: 100px;
    width: 100px;
    top: -55px;
    left: -35px;
  }
}

.ContactUsPage_main {
  margin: 0 auto;
  padding: 1rem 0;
  max-width: 1140px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.ContactUsPage_main_heading {
  display: flex;
  justify-content: center;
  background: var(--color-darkolivegreen-shade);
  padding: 2rem;
  margin: 2rem 0;
  color: white;
    margin-top: 10rem;

}
.ContactUsPage_main_heading_inner {
  font-size: 34px;
  text-shadow: 2px 2px #6f8b82;
}
.ContactUsPage_details {
  background: var(--color-lightgray);
  border-radius: 37px;
  padding: 2rem;
  margin: 0 2rem;
  height: 500px;
  box-shadow: 0px 15px 33px 0 rgba(0, 0, 0, 0.14);
  /* margin-left: 0px; */
}
.ContactUsPage_form,
.ContactUsPage_details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 470px;
}

.ContactUsPage_form {
  padding: 2rem;
}

.ContactUsPage_inner {
  display: flex;
  justify-content: space-around;
}
.ContactUsPage_map {
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
}

.ContactUsPage_details_inner {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.ContactUsPage_phoneno,
.ContactUsPage_email,
.ContactUsPage_linkdin,
.ContactUsPage_office {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.c_form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.c-txt,
.c-msg-txt {
  padding: 0.4rem;
  font-size: 16px;
  border: 2px solid;
}

.ContactUsPage_map_inner {
  border-radius: 10px;
}

.react_icons_contactpg {
  gap: 0.5rem;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 780px) {
  .ContactUsPage_inner {
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
  }
}
@media screen and (max-width: 515px) {
  .ContactUsPage_form,
  .ContactUsPage_details {
    width: 90%;
  }
}

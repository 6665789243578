.HomeHero_main {
  margin: 0 auto;
  max-width: 1140px;
  align-items: center;
  display: flex;
  padding-top: 5rem;
  justify-content: space-between;
  padding: 2rem 0;
}

.home-hero-bg {
  /* background-color: #003e29; */
  /* margin-top: 7.5rem; */
}

.bg-circles-small {
  z-index: -1;
  width: 251px;
  height: 251px;
  opacity: 0.5;
  background-image: linear-gradient(
    315deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.22)
  );
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  transform: translate(15%);
}

.heading_main_hero {
  max-width: 558px;
  display: flex;
  gap: 2rem;
  background-color: #fff;
  flex-direction: column;
}

.heading_main_h1 {
  font-size: 40px;
  font-weight: 900;
  /* color: var(--color-darkolivegreen); */
  /* color: var(--color-white); */
  color: #f69220;
}
.heading_main_p {
  /* font-size: 40px;
  font-weight: 900; */
  color: var(--color-white);
  /* color: var(--color-white); */
  color: #f69220;
}

.women_hero_main {
  height: 450px;
  background-color: #f69220;
  width: 100%;
}
.women_hero {
  height: 100%;
  width: 100%;
}

.hero_btn_main {
  display: flex;
  gap: 1.5rem;
}

@media screen and (max-width: 1140px) {
  .HomeHero_main {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 975px) {
  .women_hero {
    object-fit: contain;
  }
  .women_hero_main {
    width: 100%;
    height: 250px;
  }

  .HomeHero_main {
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }

  .heading_main_h1 {
    font-size: 30px;
    text-align: center;
  }

  .heading_main_hero {
    max-width: 100%;
    padding-top: 2rem;
    margin-top: 3rem;
  }

  .heading_main_p {
    text-align: center;
  }

  .hero_btn_main {
    justify-content: center;
  }
}

.drop-in {
  animation: drop-in 1s ease 200ms backwards;
}

.drop-in-2 {
  animation: drop-in 1200ms ease 500ms backwards;
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translate(0px);
  }
}

@media screen and (max-width: 450px) {
  .heading_main_hero {
    margin-top: 0px;
  }
}

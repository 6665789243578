.Products_main {
  max-width: 1140px;
  margin: 0 auto;
}

.Products_main_heading_inner {
  font-size: 34px;
  text-shadow: 2px 2px #6f8b82;
}

.Products_main_heading {
  display: flex;
  justify-content: center;
  background: var(--color-darkolivegreen-shade);
  padding: 2rem;
  margin: 2rem 0;
  color: white;
  margin-top: 10rem;
}
.Ecommerce_details,
.Hospital_details,
.Entertainment_details,
.channel_details,
.Franchise_details {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* padding-top: 0px; */
}
.pro_list_main {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tick_inner_main {
  display: flex;
  /* align-items: flex-start; */
  align-items: center;
  height: 30px;
  width: 30px;
}

.tick_inner {
  height: 100%;
  width: 100%;
}

.Ecommerce_main,
.Hospital_main,
.Entertainment_main,
.channel_main,
.Franchise_main {
  box-shadow: 0px 15px 33px 0 rgba(0, 0, 0, 0.14);

  display: flex;
  gap: 2rem;
  /* background: var(--color-lightgray); */
  background-color: #e3f9f5;
  border-radius: 20px;
  margin-bottom: 2rem;
}

.Entertainment_main {
  background: var(--color-lightgray);
  /* background-color: #e3f9f5; */
}

.Hospital_main {
  justify-content: space-between;
  /* background-color: var(--color-darkolivegreen); */
  background-color: var(--color-green-shade);
  color: var(--color-white);
}

.Franchise_main {
  justify-content: space-between;
  background-color: var(--color-lightgray);
  /* color: #ffffff; */
}
.channel_main {
  background-color: azure;
  justify-content: space-between;
}

.Ecommerce_img_main,
.hospital_img_main,
.Entertainment_img_main,
.channel_img_main,
.Franchise_img_main {
  max-width: 500px;
}

.Ecommerce_img_inner,
.hospital_img_inner,
.Entertainment_img_inner,
.channel_img_inner,
.Franchise_img_inner {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.hospital_img_inner,
.channel_img_inner {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.Products_details_points {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

@media screen and (max-width: 1180px) {
  .Products_main {
    max-width: 768px;
  }
  .Ecommerce_main,
  .Hospital_main,
  .Entertainment_main,
  .channel_main,
  .Franchise_main {
    flex-direction: column;
  }
  .Hospital_main,
  .channel_main {
    flex-direction: column-reverse;
    border-radius: 30px;
  }

  .Ecommerce_img_main,
  .hospital_img_main,
  .Entertainment_img_main,
  .channel_img_main,
  .Franchise_img_main {
    max-width: 100%;
    height: 550px;
  }
  .Ecommerce_img_inner,
  .hospital_img_inner,
  .Entertainment_img_inner,
  .channel_img_inner,
  .Franchise_img_inner {
    border-radius: 30px;
  }

  .Ecommerce_details,
  .Hospital_details,
  .Entertainment_details,
  .channel_details,
  .Franchise_details {
    padding-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  .Products_main {
    max-width: 600px;
  }

  .Ecommerce_img_main,
  .hospital_img_main,
  .Entertainment_img_main,
  .channel_img_main,
  .Franchise_img_main {
    max-width: 100%;
    height: 400px;
  }
}
@media screen and (max-width: 600px) {
  .Products_main {
    max-width: 400px;
  }

  .Ecommerce_img_main,
  .hospital_img_main,
  .Entertainment_img_main,
  .channel_img_main,
  .Franchise_img_main {
    max-width: 100%;
    height: 300px;
  }
}
@media screen and (max-width: 400px) {
  .Products_main {
    max-width: 350px;
  }
}
